<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
  },
  data() {
    return {
      event: null,
      buy: {
        modal: false,
        loading: false,

        step: 1,

        zipcode: '',
        address: '',
        number: '',
        complement: '',
        district: '',
        city: '',
        state: '',
      }
    };
  },
  validations: {
    buy: {
      zipcode: { required, minLength: minLength(9) },
      address: { required },
      number: { required },
      complement: {},
      district: { required },
      city: { required },
      state: { required },
    },
  },
  methods: {
    getEvent() {
      api
        .get('events/' + this.$route.params.id)
        .then((response) => {
          if (response.data.status == 'success') {
            this.event = response.data.event
          }
        })
    },
    showBuy() {
      this.buy.modal = false;
      this.buy.modal = true;
      this.buy.step = 1;
    },
    buyEvent() {
      this.$v.buy.$touch();
      if (this.$v.buy.$invalid) {
        this.buy.step = 2;
      } else {
        this.buy.loading = true;

        api
          .post("events", {
            id: this.event.id,
            zipcode: this.buy.zipcode,
            address: this.buy.address,
            number: this.buy.number,
            complement: this.buy.complement,
            district: this.buy.district,
            city: this.buy.city,
            state: this.buy.state,
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.getEvent();

              this.buy.modal = false;
              this.buy.loading = false;
              this.buy.step = 1;
              this.buy.zipcode = "";
              this.buy.address = "";
              this.buy.number = "";
              this.buy.complement = "";
              this.buy.district = "";
              this.buy.city = "";
              this.buy.state = "";

              this.$v.buy.$reset();

              this.$toast.success(response.data.message)
            } else {
              this.$toast.error(response.data.message)
            }

            this.buy.loading = false;
          })
          .catch(() => {
            this.buy.loading = false;
          });
      }
    },
    searchCep() {
      if (this.buy.zipcode.length == 9) {
        api.get("https://cep.m2n.com.br/" + this.buy.zipcode.replace(/\D/g, "")).then((response) => {
          if (response.data.city == "") {
            this.$toast.error('O CEP informado não foi encontrado.');
          } else {
            if (response.data.address) {
              this.buy.address = response.data.address;
            }
            if (response.data.district) {
              this.buy.district = response.data.district;
            }
            this.buy.city = response.data.city;
            this.buy.state = response.data.state;
          }
        });
      }
    },
  },
  mounted() {
    this.getEvent()
  }
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item"><router-link tag="a" to="/events">Multiverso Show</router-link></li>
      <li v-if="event && event.name" class="breadcrumb-item d-none d-sm-block">{{ event.name }}</li>
    </ol>

    <div v-if="event && event.name" class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>{{ event.name }}</h3>
        <p></p>
      </div>
    </div>

    <div v-if="!event" class="text-center">
      <b-spinner variant="default" role="status"></b-spinner>
    </div>
    <div v-else class="row justify-content-center">
      <div class="col-md-10 col-lg-6">
        <div class="card">
          <div class="card-body p-2">
            <iframe v-if="event.youtube" class="event-youtube rounded" :src="'https://www.youtube.com/embed/' + event.youtube + '?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=1'"
              frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <img v-else-if="event.cover" class="event-cover" :src="event.cover" />
            <div class="p-2">
              <div class="d-flex align-items-center justify-content-between mb-4">
                <div>
                  <h5 class="mb-1">{{ event.name }}</h5>
                  <div class="d-flex align-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
                      <path d="M7 11h2v2H7zm0 4h2v2H7zm4-4h2v2h-2zm0 4h2v2h-2zm4-4h2v2h-2zm0 4h2v2h-2z"></path>
                      <path d="M5 22h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2zM19 8l.001 12H5V8h14z"></path>
                    </svg>
                    <div class="ml-1 text-dark font-weight-bold">{{ event.date }}</div>
                  </div>
                </div>
                <div class="text-right">
                  <span class="bg-default rounded px-1 text-white" v-if="event.type == 'in_person'">PRESENCIAL</span>
                  <span class="bg-default rounded px-1 text-white" v-else>ONLINE</span>
                  <h5 class="mt-1 mb-0">{{ event.price | currency }}</h5>
                </div>
              </div>
              <div v-html="event.description"></div>
              <div class="d-block pt-3" v-if="event.url">
                <a target="_blank" :href="event.url" class="btn btn-outline-default text-uppercase">
                  <div class="d-flex align-items-center">
                    <span>Acessar</span>
                    <i class="bx bx-link font-size-18 ml-1"></i>
                  </div>
                </a>
              </div>
              <div v-if="event.sale && event.sale.status && event.sale.status == 'empty'" class="d-block mt-4">
                <button class="btn btn-default btn-lg btn-block text-uppercase" v-on:click="showBuy();">Comprar</button>
              </div>
              <div v-else-if="event.sale && event.sale.status && event.sale.status == 'pending'">
                <h5></h5>
                <b-tabs justified nav-class="nav-tabs-custom mt-5" content-class="p-3 text-muted">
                  <b-tab v-for="(pay, method) in event.payments" :key="method">
                    <template v-slot:title>{{ pay.name }}</template>
                    <div v-if="method == 'pix'" class="text-center">
                      <div v-if="pay.pix && pay.pix.text">
                        <div v-if="pay.pix.image">
                          <div>
                            Use o código QR para prosseguir com a transação.
                          </div>
                          <div class="my-4 d-flex justify-content-center">
                            <div class="border rounded p-3">
                              <img style="width: 180px" :src="pay.pix.image" />
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <div>
                            Use o Pix Copia e Cola para prosseguir com a transação.
                          </div>
                          <div class="my-4">
                            <b-form-textarea id="textarea-auto-height" rows="3" max-rows="8" v-model="pay.pix.text" readonly></b-form-textarea>
                          </div>
                        </div>
                        <div>
                          <button class="btn btn-default btn-lg btn-block text-uppercase" v-clipboard:copy="pay.pix.text"
                            v-on:click="$toast.success('<strong>Copiado para a área de transferência.</strong><br>Agora, tudo o que você precisa fazer é acessar o aplicativo do seu banco e concluir o processo usando o <strong>Pix Copia e Cola</strong>.')">
                            Copiar Pix Copia e Cola
                          </button>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="method == 'pagseguro'" class="text-center">
                      <img class="mt-4 mb-5" height="50px" src="@/assets/images/payments/logo-pagseguro.png"><br>
                      <a target="_blank" class="btn btn-default btn-lg btn-block text-uppercase" :href="pay.url">
                        Clique aqui para pagar
                        <svg class="align-top" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" style="fill: rgba(255, 255, 255, 1);">
                          <path d="m13 3 3.293 3.293-7 7 1.414 1.414 7-7L21 11V3z"></path>
                          <path d="M19 19H5V5h7l-2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-5l-2-2v7z"></path>
                        </svg>
                      </a>
                    </div>
                    <div v-else-if="method == 'pagarme'" class="text-center">
                      <a target="_blank" class="btn btn-default btn-lg btn-block text-uppercase" :href="pay.url">
                        Clique aqui para pagar com cartão de crédito
                        <svg class="align-top" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" style="fill: rgba(255, 255, 255, 1);">
                          <path d="m13 3 3.293 3.293-7 7 1.414 1.414 7-7L21 11V3z"></path>
                          <path d="M19 19H5V5h7l-2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-5l-2-2v7z"></path>
                        </svg>
                      </a>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
              <div v-else-if="event.sale && event.sale.status && event.sale.status == 'approved'">
                <div class="py-4 text-center text-success d-flex align-items-center justify-content-center" style="gap: 5px">
                  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24" style="fill: rgba(52, 195, 145, 1);">
                    <path d="m2.394 13.742 4.743 3.62 7.616-8.704-1.506-1.316-6.384 7.296-3.257-2.486zm19.359-5.084-1.506-1.316-6.369 7.279-.753-.602-1.25 1.562 2.247 1.798z"></path>
                  </svg>
                  <h5 class="m-0 font-size-15 text-success pt-1">
                    PAGAMENTO CONFIRMADO!
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="buy.modal" v-if="event && event.gift && event.gift.status" modal-class="modal-right" hide-header hide-footer>
      <div class="d-flex justify-content-between mb-5">
        <h4 class="m-0">Comprar Ingresso</h4>
        <a class="btn btn-outline-link p-0" v-on:click="create.modal = false">Fechar</a>
      </div>
      <template v-if="event.gift.status == 'yes'">
        <b-progress :value="buy.step" max="3" class="mb-5" variant="default"></b-progress>

        <template v-if="buy.step == 1">
          <div class="mb-3">
            Ao realizar a compra do evento <strong>"{{ event.name }}"</strong> você ganhará um brinde exclusivo.
          </div>
          <div class="border rounded p-4 mb-3">
            <h5>{{ event.gift.name }}</h5>
            <div v-html="event.gift.description"></div>
            <img class="event-cover" :src="event.gift.image" />
          </div>
          <div class="mt-5">
            <button class="btn btn-default btn-lg btn-block text-uppercase" v-on:click="buy.step = 2">
              Avançar
            </button>
          </div>
        </template>

        <template v-else-if="buy.step == 2">
          <b-form @submit.prevent="buyEvent">
            <p>Para confirmar o seu pedido preencha o endereço para a entrega do seu brinde.</p>
            <b-form-group id="buy.zipcode" label="CEP" label-for="buy.zipcode">
              <b-form-input v-on:blur="searchCep()" id="buy.zipcode" v-model="buy.zipcode" type="text" inputmode="numeric" :class="{ 'is-invalid': $v.buy.zipcode.$error }" autocomplete="off"
                v-mask="'#####-###'"></b-form-input>
              <div v-if="$v.buy.zipcode.$error" class="invalid-feedback">
                <span v-if="!$v.buy.zipcode.required">O CEP é obrigatório.</span>
                <span v-if="!$v.buy.zipcode.invalid">O CEP informado é invalido.</span>
              </div>
            </b-form-group>
            <b-form-group id="buy.address" label="Endereço" label-for="buy.address">
              <b-form-input id="buy.address" v-model="buy.address" type="text" :class="{ 'is-invalid': $v.buy.address.$error }" autocomplete="off"></b-form-input>
              <div v-if="$v.buy.address.$error" class="invalid-feedback">
                <span v-if="!$v.buy.address.required">O endereço é obrigatório.</span>
              </div>
            </b-form-group>
            <b-form-group id="buy.number" label="Número" label-for="buy.number">
              <b-form-input id="buy.number" v-model="buy.number" type="text" :class="{ 'is-invalid': $v.buy.number.$error }" autocomplete="off"></b-form-input>
              <div v-if="$v.buy.number.$error" class="invalid-feedback">
                <span v-if="!$v.buy.number.required">O número é obrigatório.</span>
              </div>
            </b-form-group>
            <b-form-group id="buy.complement" label="Complemento" label-for="buy.complement">
              <b-form-input id="buy.complement" v-model="buy.complement" type="text" :class="{ 'is-invalid': $v.buy.complement.$error }" autocomplete="off"></b-form-input>
              <div v-if="$v.buy.complement.$error" class="invalid-feedback">
                <span v-if="!$v.buy.complement.required">O complemento é obrigatório.</span>
              </div>
            </b-form-group>
            <b-form-group id="buy.district" label="Bairro" label-for="buy.district">
              <b-form-input id="buy.district" v-model="buy.district" type="text" :class="{ 'is-invalid': $v.buy.district.$error }" autocomplete="off"></b-form-input>
              <div v-if="$v.buy.district.$error" class="invalid-feedback">
                <span v-if="!$v.buy.district.required">O bairro é obrigatório.</span>
              </div>
            </b-form-group>
            <b-form-group id="buy.city" label="Cidade" label-for="buy.city">
              <b-form-input id="buy.city" class="bg-soft-info" v-model="buy.city" type="text" :class="{ 'is-invalid': $v.buy.city.$error }" autocomplete="off" readonly="readonly"></b-form-input>
              <div v-if="$v.buy.city.$error" class="invalid-feedback">
                <span v-if="!$v.buy.city.required">A cidade é obrigatória.</span>
              </div>
            </b-form-group>
            <b-form-group id="buy.state" label="Estado" label-for="buy.state">
              <b-form-input id="buy.state" class="bg-soft-info" v-model="buy.state" type="text" :class="{ 'is-invalid': $v.buy.state.$error }" autocomplete="off" readonly="readonly"
                v-mask="'XX'"></b-form-input>
              <div v-if="$v.buy.state.$error" class="invalid-feedback">
                <span v-if="!$v.buy.state.required">O estado é obrigatório.</span>
              </div>
            </b-form-group>
            <div class="mt-4">
              <button class="btn btn-default btn-lg btn-block text-uppercase" v-on:click="buy.step = 3;" :disabled="this.$v.buy.$invalid">
                Avançar
              </button>
              <button class="btn btn-outline-default btn-lg btn-block text-uppercase" v-on:click="buy.step = 1">
                Voltar
              </button>
            </div>
          </b-form>
        </template>

        <template v-else-if="buy.step == 3">
          <div class="border rounded p-4 mb-3 d-flex align-items-center" style="gap: 15px;">
            <div>
              <img class="rounded" style="width: 100px;" :src="event.gift.image" />
            </div>
            <div>
              <h5 class="mb-0">{{ event.gift.name }}</h5>
            </div>
          </div>
          <div class="border rounded p-4 mb-3">
            <h5>Endereço de Entrega</h5>
            <div>
              {{ buy.address }}, {{ buy.number }}<template v-if="buy.complement">, {{ buy.complement }}</template>,
              {{ buy.district }}, {{ buy.city }}/{{ buy.state }}, {{ buy.zipcode }}
            </div>
          </div>
          <div class="mt-4">
            <button class="btn btn-default btn-lg btn-block text-uppercase" v-on:click="buyEvent();" :disabled="this.$v.buy.$invalid || buy.loading">
              Confirmar
              <b-spinner v-if="buy.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
            </button>
            <button class="btn btn-outline-default btn-lg btn-block text-uppercase" v-on:click="buy.step = 2">
              Voltar
            </button>
          </div>
        </template>
      </template>
      <template v-else>
        <b-progress :value="buy.step" max="2" class="mb-5" variant="default"></b-progress>

        <template v-if="buy.step == 1">
        </template>

        <template v-else-if="buy.step == 2">
        </template>
      </template>
    </b-modal>
  </Layout>
</template>

<style scoped>
.event-cover {
  border-radius: 5px;
  margin-bottom: 20px;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 16 / 9;
}
.event-youtube {
  margin-bottom: 20px;
  width: 100%;
  aspect-ratio: 16 / 9;
}
</style>
